import * as CpfCnpj from './cpfCnpj'
import * as Date from './date'
import * as Fone from './fone'
import * as Decimal from './decimal'
import * as Currency from './currency'
import * as Cep from './cep'
import * as TimeStamp from './timeStamp'
import * as TimeStampNow from './timeStampNow'

export default {
  CpfCnpj,
  Date,
  Fone,
  Decimal,
  Currency,
  Cep,
  TimeStamp,
  TimeStampNow
}
