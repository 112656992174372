import Vue from 'vue'
import http from './plugins/http'
import interceptor from './plugins/http/interceptor'
import './registerServiceWorker'
import router from './router/index'
import store from './vuex/store'
import filters from './filters/index'
import directives from './directives/index'
import './filters/capitalize'
import './quasar'
import vuelidate from 'vuelidate'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import SocketService from './services/Socket.service'
import * as GmapVue from 'gmap-vue'
const App = () => import(/* webpackChunkName: "eCheap" */ './App.vue')

Vue.use(http, { interceptor })
Vue.use(vuelidate)
Vue.use(Croppa)
Vue.use(SocketService)
Vue.use(GmapVue, {
  load: {
    region: 'BR',
    language: 'pt',
    key: 'AIzaSyApgK5wFmLEi-zXZtwcLfvNnZDFu9w3B6Q',
    v: 'weekly',
    libraries: 'places'
  },
  installComponents: true
})
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  store,
  router,
  filters,
  directives,
  render: h => h(App)
}).$mount('#app')
