import Notify from 'quasar/src/plugins/Notify.js';

const sendNotification = ({ color = 'positive', icon, message, caption, progress = true, timeout = 5000, position = 'top-right', err }) => {
  if (err) {
    console.error(err)
  }
  const iconDef = icon || (color === 'positive' ? 'fas fa-check' : ((color === 'negative' || color === 'warning') ? 'fas fa-exclamation-triangle' : ''))
  const captionDef = caption || (color === 'negative' ? 'Tente novamente ou procure o suporte!' : '')
  Notify.create({
    message: message,
    caption: captionDef,
    progress: progress,
    timeout: timeout,
    icon: iconDef,
    color: color,
    position: position
  })
}

export {
  sendNotification
}

export default {
  sendNotification
}
