import Vue from 'vue'

const type = Vue.config.productionTip ? 'dev' : 'prod'

const configs = {
  prod: {
    API_IP: 'https://api.planos.dev/e-cheap/',
    SOCKET_IP: 'https://api.planos.dev'
  },
  dev: {
    API_IP: 'http://localhost:3000/v1/',
    SOCKET_IP: 'http://localhost:9000'
  }
}

export default configs[type]
