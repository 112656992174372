import store from '../../vuex/store'
import router from '../../router/index'
import Loading from 'quasar/src/plugins/Loading.js';
import { sendNotification } from '../notify'

let requestsTotal = 0
let requestsCompleted = 0
const latencyThreshold = 100
const responseLatency = 50
const setComplete = () => {
  requestsTotal = 0
  requestsCompleted = 0
  if (Loading.isActive) {
    Loading.hide()
  }
}

export default (http) => {
  // Intercepção das requisições
  http.interceptors.request.use(request => {
    const isLoading = request.headers.requestLoading === undefined || request.headers.requestLoading === null ? true : request.headers.requestLoading
    if (!request.url.includes('ipify')) {
      const authToken = store.getters.getAuthToken
      if (authToken) {
        request.headers.Authorization = `Bearer ${authToken}`
      }
    }
    if (isLoading) {
      if (requestsTotal === 0) {
        if (latencyThreshold === 0) {
          Loading.show()
        } else {
          setTimeout(() => {
            if (requestsTotal !== requestsCompleted) {
              Loading.show()
            }
          }, latencyThreshold)
        }
      }
      requestsTotal++
    }
    return request
  }, error => {
    Promise.reject(error)
  })
  // Intercepção das respostas
  http.interceptors.response.use(response => {
    response.body = response.data
    setTimeout(() => {
      requestsCompleted++
      if (requestsCompleted >= requestsTotal) {
        setComplete()
      }
    }, responseLatency)
    return response
  }, error => {
    setTimeout(() => {
      requestsCompleted++
      if (requestsCompleted >= requestsTotal) {
        setComplete()
      }
    }, responseLatency)
    if (error.response && error.response.status === 401) {
      store.dispatch('setAuthToken', null)
      store.dispatch('setUser', null)
      store.dispatch('setLocality', null)
      if (!error.response.config.url.includes('user/login')) {
        router.push({ path: '/login' })
        sendNotification({ color: 'info', message: 'Sua sessão expirou, efetue o login novamente.', timeout: 10000 })
      }
    }
    return Promise.reject(error.response)
  })
}
