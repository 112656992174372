import Vue from 'vue'

export default Vue.filter('currency', (value) => {
  if (value !== undefined) {
    if (!isNaN(parseFloat(value)) && isFinite(value)) {
      return parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })
    } else {
      return value
    }
  }
})
