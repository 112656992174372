import axios from 'axios'

const http = (Vue, { baseURL = '', interceptor }) => {
  const instance = axios.create({
    baseURL
  })

  interceptor(instance)
  Object.defineProperties(Vue.prototype, {
    $http: {
      get: () => instance
    }
  })
}

export default http
